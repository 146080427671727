import React from "react"

import Seo from "../components/seo"
import { motion } from "framer-motion"
import { motionItem } from "../utils/motion"

const Impressum = () => {
  return (
    <>
      <Seo title="Impressum" />
      <motion.h1 variants={motionItem}>Impressum</motion.h1>
      <motion.p variants={motionItem}>
        <p>
          Angaben gemäß Informationspflicht laut §5 E-Commerce Gesetz, §14
          Unternehmensgesetzbuch, §63 Gewerbeordnung und Offenlegungspflicht
          laut §25 Mediengesetz.
        </p>
        <p>
          Julian Berger
          <br />
          Friedrich-Kaiser-Gasse, 73/1, <br />
          1160 Wien, <br />
          Österreich
        </p>
        <p>
          <strong>Unternehmensgegenstand:</strong> IT Dienstleistungen
          <br />
          <strong>UID-Nummer:</strong> ATU12345678
          <br />
          <strong>GLN:</strong> 9110032071945
          <br />
          <strong>GISA:</strong> 34829929
        </p>
        <p>
          <strong>E-Mail:</strong>{" "}
          <a href="mailto:kontakt@julianberger.de">kontakt@julianberger.de</a>
        </p>
        <p>
          <strong>Mitglied bei:</strong> WKO
          <br />
          <strong>Berufsrecht:</strong> Gewerbeordnung: www.ris.bka.gv.at
        </p>
        <p>
          <strong>Aufsichtsbehörde/Gewerbebehörde:</strong> Magistratischen
          Bezirksamt, Wien, 16. Bezirk
          <br />
          <strong>Berufsbezeichnung:</strong> Programmierer
        </p>
        <p>
          <strong>Kontaktdaten des Verantwortlichen für Datenschutz</strong>
          <br />
          Sollten Sie Fragen zum Datenschutz haben, finden Sie nachfolgend die
          Kontaktdaten der verantwortlichen Person bzw. Stelle:
          <br />
          Julian Berger
          <br />
          E-Mail-Adresse:{" "}
          <a href="mailto:datenschutz@julianberger.de">
            datenschutz@julianberger.de
          </a>
        </p>
        <h2 id="eu-streitschlichtung">
          EU-Streitschlichtung
        </h2>
        <p>
          Gemäß Verordnung über Online-Streitbeilegung in
          Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über die
          Online-Streitbeilegungsplattform (OS-Plattform) informieren.
          <br />
          Verbraucher haben die Möglichkeit, Beschwerden an die Online
          Streitbeilegungsplattform der Europäischen Kommission unter{" "}
          <a
           
            href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=DE"
            target="_blank"
            rel="follow noopener"
          >
            https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=DE
          </a>{" "}
          zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in
          unserem Impressum.
        </p>
        <p>
          Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder
          verpflichtet sind, an Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle teilzunehmen.
        </p>
        <h2
          id="haftung-fuer-inhalte-dieser-webseite"
         
        >
          Haftung für Inhalte dieser Website
        </h2>
        <p>
          Wir entwickeln die Inhalte dieser Website ständig weiter und bemühen
          uns korrekte und aktuelle Informationen bereitzustellen. Leider können
          wir keine Haftung für die Korrektheit aller Inhalte auf dieser Website
          übernehmen, speziell für jene, die seitens Dritter bereitgestellt
          wurden. Als Diensteanbieter sind wir nicht verpflichtet, die von Ihnen
          übermittelten oder gespeicherten Informationen zu überwachen oder nach
          Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
        </p>
        <p>
          Unsere Verpflichtungen zur Entfernung von Informationen oder zur
          Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
          aufgrund von gerichtlichen oder behördlichen Anordnungen bleiben auch
          im Falle unserer Nichtverantwortlichkeit davon unberührt.
        </p>
        <p>
          Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen,
          bitte wir Sie uns umgehend zu kontaktieren, damit wir die
          rechtswidrigen Inhalte entfernen können. Sie finden die Kontaktdaten
          im Impressum.
        </p>
        <h2 id="haftung-links-webseite">
          Haftung für Links auf dieser Website
        </h2>
        <p>
          Unsere Website enthält Links zu anderen Websites für deren Inhalt wir
          nicht verantwortlich sind. Haftung für verlinkte Websites besteht für
          uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und
          haben, uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen
          sind und wir Links sofort entfernen würden, wenn uns
          Rechtswidrigkeiten bekannt werden.
        </p>
        <p>
          Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte
          wir Sie uns zu kontaktieren. Sie finden die Kontaktdaten im Impressum.
        </p>
        <h2 id="urheberrechtshinweis">
          Urheberrechtshinweis
        </h2>
        <p>
          Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos)
          unterliegen dem Urheberrecht. Bitte fragen Sie uns bevor Sie die
          Inhalte dieser Website verbreiten, vervielfältigen oder verwerten wie
          zum Beispiel auf anderen Websites erneut veröffentlichen. Falls
          notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte
          unserer Seite rechtlich verfolgen.
        </p>
        <p>
          Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht
          verletzen, bitten wir Sie uns zu kontaktieren.
        </p>
        <h2 id="bildernachweis">
          Bildernachweis
        </h2>
        <p>
          Die Bilder, Fotos und Grafiken auf dieser Webseite sind
          urheberrechtlich geschützt.
        </p>
        <p>
          <p>Die Bilderrechte liegen bei:</p>
          <p>
            <a href="https://www.freepik.com/free-photo/executive-manager-using-online-teleconference-conversation-internet-talking-people-videoconference-call-remote-telecommunication-wireless-business-meeting-handheld-shot_25858340.htm">
              Image by DCStudio
            </a>{" "}
            on Freepik
          </p>
          <p>
            Image by{" "}
            <a href="https://www.freepik.com/free-photo/homepage-concept-with-search-bar_36029326.htm#query=searching%20laptop&amp;position=30&amp;from_view=search&amp;track=ais">
              Freepik
            </a>
          </p>
          <p>
            Image by{" "}
            <a href="https://www.freepik.com/free-vector/flat-roadmap-infographic-template_16379609.htm#query=timeline&amp;position=5&amp;from_view=search&amp;track=sph">
              Freepik
            </a>
          </p>
          <p>
            <a href="https://www.freepik.com/free-photo/coding-man_5633683.htm#query=coding&amp;position=1&amp;from_view=search&amp;track=sph">
              Image by pressfoto
            </a>{" "}
            on Freepik
          </p>
          <p>
            <a href="https://www.freepik.com/free-photo/two-businessmen-pointing-laptop-screen-while-discussing_7627613.htm#query=meeting%20computer&amp;position=4&amp;from_view=search&amp;track=ais">
              Image by ArthurHidden
            </a>{" "}
            on Freepik
          </p>
          <p>
            <a href="https://www.freepik.com/free-photo/digital-world-banner-background-remixed-from-public-domain-by-nasa_17121612.htm#query=earth%20satelite&amp;position=9&amp;from_view=search&amp;track=ais">
              Image by rawpixel.com
            </a>{" "}
            on Freepik
          </p>
          <p>
            <a href="https://www.freepik.com/free-photo/diverse-team-coworkers-looking-business-charts-documents-analyzing-research-information-statistics-diagrams-review-people-using-papers-with-infographics-data-report-close-up_31993425.htm#query=review&amp;position=49&amp;from_view=search&amp;track=sph">
              Image by DCStudio
            </a>{" "}
            on Freepik
          </p>
          <p>
            <a href="https://www.freepik.com/free-photo/feedback-comment-survey-support-response-bar-word_17122761.htm#query=evaluation&amp;position=34&amp;from_view=search&amp;track=sph">
              Image by rawpixel.com
            </a>{" "}
            on Freepik
          </p>
          <p>
            Image by{" "}
            <a href="https://www.freepik.com/free-photo/businessman-doing-ok-sign-while-holding-growth-chart_11383081.htm#query=increasing%20sales&amp;position=49&amp;from_view=search&amp;track=ais">
              Freepik
            </a>
          </p>
          <p>
            <a href="https://www.freepik.com/free-photo/closeup-hands-passing-contract-unrecognizable-businessman_5766389.htm#query=documentation&amp;position=26&amp;from_view=search&amp;track=sph">
              Image by pressfoto
            </a>{" "}
            on Freepik
          </p>
          <p>
            <a href="https://www.freepik.com/free-vector/software-engineering-background_1528600.htm#query=clean%20code&amp;position=34&amp;from_view=search&amp;track=ais">
              Image by macrovector
            </a>{" "}
            on Freepik
          </p>
        </p>
        <p>Alle Texte sind urheberrechtlich geschützt.</p>
      </motion.p>
    </>
  )
}

export default Impressum
